import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { WebInAppID } from '@/lib/common';
import useIsMobile from '@/lib/useIsMobile';
import DownloadApp from '../../DownloadApp';

export default function TopFooter() {
  const { locale, query } = useRouter();
  const isMobile = useIsMobile();
  const isAhadriverWebinapp = query.webinapp === WebInAppID.AHA_DRIVER;

  return (
    <div className="desktop:flex desktop:items-center desktop:justify-between space-y-6">
      <div className="h-7 w-40 ">
        <Link
          href="/"
          passHref
          locale={locale}
          className="inline-block"
          title="HomePage"
        >
          <div className="relative h-7 w-40">
            <Image src="/static/icons/Logo.svg" alt="Logo" fill priority />
          </div>
        </Link>
      </div>
      {!isAhadriverWebinapp ? (
        <DownloadApp
          className="desktop:mt-0 desktop:justify-end mt-2 justify-start"
          hasQR={false}
          type="row"
        />
      ) : null}
    </div>
  );
}
