import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { WebInAppID } from '@/lib/common';
import { baseUrl } from '@/lib/constants';
import ButtonScrollTop from './ButtonScrollTop';
import Footer from './Footer';
import Header from './Header';

type ContainerProps = Omit<NextSeoProps, 'children'> & {
  children: ReactNode;
  blank?: boolean;
};
export default function Container({
  children,
  blank = false,
  ...props
}: ContainerProps) {
  const { locale, asPath, query } = useRouter();
  const {
    title,
    description = locale === 'vi'
      ? 'Giải pháp hoàn hảo cho mọi nhu cầu giao hàng của bạn'
      : 'The ultimate solution for all delivery demands',
    openGraph,
    ...restProps
  } = props;

  const cleanPath = asPath.split('#')[0].split('?')[0];
  const canonicalUrl = `${baseUrl}` + (asPath === '/' ? '' : cleanPath);

  return (
    <>
      <NextSeo
        title={title}
        titleTemplate={`%s | ${process.env.NEXT_PUBLIC_APP_NAME}`}
        defaultTitle={process.env.NEXT_PUBLIC_APP_NAME}
        description={description}
        canonical={canonicalUrl}
        openGraph={{
          url: canonicalUrl,
          type: 'website',
          site_name: process.env.NEXT_PUBLIC_APP_NAME,
          description,
          title: title || process.env.NEXT_PUBLIC_APP_NAME,
          images: [
            {
              url: `${baseUrl}/static/images/home/banner-faster-delivery-desktop.webp`,
              height: 627,
              width: 1200,
              alt: title || process.env.NEXT_PUBLIC_APP_NAME,
            },
          ],
          ...openGraph,
        }}
        twitter={{
          handle: '@ahamove',
          cardType: 'summary_large_image',
          site: '@ahamove',
        }}
        {...restProps}
      />
      {blank || query?.webinapp === WebInAppID.AHA_DRIVER ? null : <Header />}
      {children}
      <ButtonScrollTop />
      {blank || query?.webinapp === WebInAppID.AHA_DRIVER ? null : <Footer />}
    </>
  );
}
